.MissionVision {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 32px 64px;
    margin: 30px 20px 100px;
    color: var(--secondary);
    /* max-width: 1000px; */
    background-color: white;
    box-shadow: 0px 5px 10px #0000001a;
    border-radius: 20px;
}

.MV_head {
    font-size: 36px;
    color: #0000c4;
    font-family: 'myfontblack';
    font-weight: 800;
    margin-bottom: 32px;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.WCU_card {
    display: grid;
    grid-template-columns: 120px auto;
    gap: 32px;
    padding: 20px;
    background-color: var(--grey);
    border-radius: 20px;
    text-align: left;
    width: 100%;
    max-width: 680px;
    align-self: center;
}

.WCU_card.right {
    grid-template-columns: auto 120px;
    align-self: center;
    text-align: right;
}

.WCU_img {
    width: 100%;
}

.WCU_img img {
    object-fit: contain;
    width: 100%;
}

.WCU_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
}

.WCU_content.right {
    align-items: flex-end;
}

.WCU_head {
    font-size: 30px;
    font-weight: 700;
    color: var(--primary);
}

.WCU_text {
    font-size: 15px;
    max-width: 420px;
}


@media only screen and (orientation: portrait) {
    .MissionVision {
        padding: 32px 20px;
        margin: 30px 20px 56px;
    }

    .MV_head {
        font-size: 24px;
    }

    .WCU_card {
        grid-template-columns: 80px auto;
        gap: 10px;
        padding: 16px;
        width: calc(100% - 32px);
        align-self: stretch;
    }

    .WCU_card.right {
        grid-template-columns: auto 80px;
        align-self: stretch;
    }

    .WCU_head {
        font-size: 24px;
    }

    .WCU_text {
        font-size: 13px;
    }

    .WCU_img {
        /* width: calc(100% - 10px); */
    }
}


.certifications{
    display: grid;
    grid-template-columns: repeat(5, auto);
    gap: 32px;
    justify-content: space-between;
    align-items: center;
    margin: 60px 0;
}

.certiLogo{
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 120px;
}

.certiLogo img{
    width: 100%;
    object-fit: contain;
    height: 100%;
}

@media only screen and (orientation: portrait) {
    .certifications{
        grid-template-columns: repeat(5, auto);
        gap: 20px;
        margin: 40px 0;
    }
}