.NavContainer{
    position: fixed;
    top: 16px;
    left: 20px;
    right: 20px;
    z-index: 2;
}

.Nav {
    border-radius: 10px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 8px 20px;
    box-shadow: 0px 5px 10px #0000001a;
    z-index: 2;
    max-width: 1100px;
    margin: 0 auto;
}

.navLogo {
    height: 50px;
}

.navLogo img {
    height: 100%;
    object-fit: contain;
}

.navItems {
    /* display: flex; */
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 14px;
    align-items: center;
    /* justify-content: space-around; */
    font-size: 14px;
}

.navitem {
    padding: 12px 12px;
    min-width: fit-content;
    border-radius: 10px;
    transition: 100ms ease-in-out;
    text-transform: uppercase;
    /* letter-spacing: 1px; */
}

.navitem.selected,
.navitem:hover {
    background-color: var(--primary);
    color: white;
}

.navSearch {
    position: relative;
}

.searchIcon{
    position: absolute;
    top: 8px;
    right: 12px;
    bottom: 8px;
}
.searchIcon img{
    object-fit: contain;
    margin: auto 0;
    height: 100%;
}

.navInput {
    border-radius: 30px;
    border: 1px solid #0000005d;
    padding: 8px 16px;
    padding-right: 32px;
    max-width: 280px;
    /* min-width: calc(100% - 32px); */
}

.Navdropdown {
    position: absolute;
    top: 40px;
    right: 0px;
    left: 0;
    background-color: white;
    padding: 16px;
    padding-left: 24px;
    min-width: 130px;
    border-radius: 10px;
    max-height: 450px;
    overflow-y: scroll;
}

.dropdown-item {
    margin: 8px 0 2px;
    text-align: left;
    font-size: 14px;
    /* color: #111111; */
    color: var(--primary);
    font-family: 'myfontbold';
}

.dropdown-item:hover {
    /* color: black; */
    font-family: 'myfontblack';
    cursor: pointer;
}

.product-codes div{
    padding-left: 8px;
    text-align: left;
    font-size: 10px;
    color: #737373;
    margin: 3px 0;
}

.product-codes div:hover {
    color: black;
    font-weight: 500;
    cursor: pointer;
}

.hamburgerNav {
    display: none;
    font-size: 40px;
    cursor: pointer;
}

.closeNavBtn {
    font-size: 40px;
    text-align: right;
    cursor: pointer;
}

.mobileNavContainer {
    display: flex;
    position: fixed;
    z-index: 3;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.534);
    justify-content: flex-end;
}

.mobileNav {
    max-width: 300px;
    width: 100%;
    padding: 10px 32px 20px;
    background-color: white;
    height: calc(100lvh - 30px);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.mobilenavItems {
    display: flex;
    flex-direction: column;
    gap: 4px;
    /* align-items: center; */
    /* flex-grow: 1; */
    font-size: 14px;
}

.mobilenavitem {
    padding: 12px 19px;
    border-radius: 10px;
    transition: 100ms ease-in-out;
    text-align: left;
}

.mobilenavitem.selected,
.mobilenavitem:hover {
    background-color: var(--primary);
    color: white;
}

.mobilenavSearch {
    position: relative;
}

.mobilenavSearch input {
    width: calc(100% - 48px);
}

.ProductDropDownDiv{
    position: relative;
}

@media only screen and (max-width: 961px) {
    .navItems {
        display: none;
    }

    .navSearch {
        display: none;
    }

    .hamburgerNav {
        display: block;
    }

    .Navdropdown {
        position: absolute;
        top: 40px;
        right: 0px;
        left: 0;
        background-color: var(--grey);
        padding: 16px;
        padding-left: 24px;
        min-width: 130px;
        border-radius: 10px;
        max-height: 250px;
        overflow-y: scroll;
    }
}