.ContactUs {
    display: flex;
    padding: 110px 64px;
    flex-direction: column;
    gap: 32px;
}

.CU_Container {
    display: flex;
    gap: 24px;
    position: relative;
    min-height: calc(100vh - 220px);
    justify-content: center;
}

.CU_infoDiv {
    display: flex;
    flex-direction: column;
    padding: 32px 16px 24px;
    background-color: white;
    box-shadow: 0px 5px 10px #0000001a;
    border-radius: 10px;
    text-align: left;
    gap: 20px;
    max-width: 350px;
    position: sticky;
    top: 120px;
    align-self: flex-start;
    margin-top: 12px;
    color: var(--secondary);
    /* font-family: 'myfontbold'; */
    height: 100%;
    max-height: calc(100vh - 120px);
    font-size: 14px;
}

.CU_flex {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 0 8px;
}

.CU_icon {
    height: 20px;
    width: 20px;
}

.CU_icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.iframeDiv {
    max-width: 100%;
}

.iframeDiv iframe {
    width: 100%;
}

.iframeDiv.map {
    /* min-height: 100%; */
    max-height: 100%;
    /* flex-grow: 1; */
}

.mapiframe {
    height: 100%;
}

.mapiframeMobile{
    display: none;
}

.iframeDiv.googleform {
    /* flex-grow: 1; */
    width: 100%;
    max-width: 650px;
    margin: 0 0 !important;
    /* height: 100%; */
}

.iframeDiv.googleform iframe {
    /* height: 100%; */
    /* min-height: 100% !important; */
    /* overflow-y: visible; */
}

.iframeDiv.googleform iframe div {
    margin: 0 !important;
    width: unset !important;
}

.googleformMobile {
    display: none;
    font-size: 20px;
    margin-top: 32px;
    padding: 20px;
    /* background-color: white;
    box-shadow: 0px 5px 10px #0000001a;
    border-radius: 10px; */
}

@media only screen and (max-width: 991px) {
    .CU_infoDiv {
        max-width: 250px;
    }
}

@media only screen and (max-width: 861px) {
    .ContactUs {
        padding: 110px 20px;
    }

    .CU_Container {
        flex-direction: column;
        min-height: unset;
    }

    .CU_infoDiv {
        padding: 24px 16px 24px;
        gap: 20px;
        max-width: unset;
        width: calc(100% - 32px);
        position: static;
        top: unset;
        align-self: flex-start;
        height: 100%;
        font-size: 14px;
        margin-top: 0;
        min-height: calc(100vh - 320px);
        max-height: unset;
    }

    .iframeDiv.googleform {
        display: none;
    }

    .googleformMobile {
        display: block;
    }

    .iframeDiv.map {
        min-height: 100%;
        max-height: 100%;
        flex-grow: 1;
    }

    .mapiframe {
        display: none;
    }
    
    .mapiframeMobile{
        display: block;
    }
}