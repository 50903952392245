.HomeMain {
    /* padding: 114px 20px 32px; */
    /* min-height: calc(100vh - 146px); */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    gap: 60px;
}

.mainHeader {
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    text-align: left;
    justify-items: center;
    align-items: center;
    height: 100%;
    flex-grow: 1;
    background-image: url(../../../assets/main.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    color: white;
    /* border-radius: 20px; */
}

.mainHeading {
    font-size: 80px;
}

.mainText {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    max-width: 480px;
    text-align: left;
    margin-top: 66px;
}

.mainTagline {
    font-size: 26px;
    /* font-style: italic; */
    /* font-family: ; */
    margin-bottom: 12px;
    /* text-transform: uppercase; */
}

.italic {
    font-style: italic;
    padding-right: 0px;
}

.orange {
    color: var(--primary);
}

.blue {
    /* color: var(--primary); */
    /* color: white; */
}

.button {
    border-radius: 30px;
    background-color: var(--secondary);
    color: white;
    border: none;
    padding: 6px 16px;
}

.mainImg {
    /* width: 100%; */
    height: 260px;
    border-radius: 40px;
}

.mainImg img {
    object-fit: contain;
    height: 100%;
    border-radius: 40px;
    max-height: 100%;
}

.mainLogo{
    margin-left: -4px;
    height: 200px;
}

.mainLogo img{
    object-fit: contain;
    height: 100%;
    border-radius: 40px;
    max-height: 100%;
    max-width: 100%;
}

@media only screen and (max-width: 780px) {
    
    .mainHeader {
        display: grid;
        grid-template-columns: auto 1fr;
        padding: 32px;
    }

}


@media only screen and (max-width: 400px) {
    
    .mainHeading {
        font-size: 56px;
    }

    .mainTagline {
        font-size: 18px;
    }
}