.AboutUs{
    padding: 100px 20px 16px;
}

.aboutUsBox{
    background-image: url(../../../assets/aboutbg.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    min-height: calc(100vh - 132px);
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    /* padding: 32px; */
    justify-items: center;
}

.aboutUsContent{
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: white;
    text-align: justify;
    align-self: center;
    padding: 32px;
    font-size: 13px;
    max-width: 600px;
}

.aboutHead{
    font-size: 48px;
    font-weight: 700;
    color: var(--primary);
    font-family: 'myfontbold';
    text-align: left;
}

.aboutUsImg{
    border-radius: 20px;
    width: calc(100% - 64px);
    padding: 32px;
}

.aboutUsImg img{
    object-fit: cover;
    border-radius: 20px;
    width: 100%;
    /* margin: 32px; */
}

@media only screen and (max-width: 900px) {
    
    .aboutUsBox{
        grid-template-columns: 1fr 1fr;
    }

}

@media only screen and (max-width: 780px) {
    .AboutUs{
        padding: 100px 20px 16px;
    }
    
    .aboutUsBox{
        display: grid;
        grid-template-columns: 1fr;
    }
    
    .aboutUsContent{
        font-size: 13px;
    }
    
    .aboutHead{
        font-size: 40px;
    }
    
    .aboutUsImg{
        display: none;
        width: calc(100% - 64px);
        padding: 32px;
        height: 200px;
    }
    
    .aboutUsImg img{
        width: 100%;
        height: 100%;
        object-position: center;
    }
}


@media only screen and (orientation: portrait) {
    .aboutHead{
        font-size: 40px;
    }
}