.Header{
    padding: 84px 64px 0px;
    background-image: url(../../../assets/productsheader.png);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 32px;
    letter-spacing: 8px;
}