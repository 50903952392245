.Solutions {
    background-color: white;
    padding: 60px 64px 60px;
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.solutionsContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: var(--grey);
    border-radius: 20px;
    padding: 32px;
    box-shadow: 0px 5px 10px #0000001a;
}

.SC_Grid {
    display: grid;
    grid-template-columns: 1.1fr 1fr;
    gap: 32px;
    /* text-align: justify; */
}


.SC_Header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    max-width: 500px;
    justify-self: center;
}

.SC_head {
    color: var(--secondary);
    font-weight: 700;
    font-size: 25px;
    text-align: left;
    font-family: 'myfontblack';
}
.SC_head + .SC_text{
    text-align: left;
}

.SC_head.one {
    text-align: right;
}

.SC_head.one + .SC_text{
    text-align: right;
}

.SC_text {
    font-size: 13px;
    max-width: 280px;
}
.SC_text.head{
    max-width: unset;
}

.SC_img {
    width: 100%;
    height: 100%;
    max-height: 140px;
}

.SC_img img {
    object-fit: cover;
    border-radius: 20px;
    width: 100%;
    max-height: 100%;
}

.SC_Cardsflex {
    display: flex;
    gap: 16px;
    justify-content: space-between;
}

.SC_card {
    background-color: white;
    border-radius: 30px;
    padding: 48px 24px 48px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    width: 30%;
    /* text-align: left; */
}

.SC_card.one {
    width: 25%;
    /* text-align: right; */
}

.SC_cardIcon {
    height: 120px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
    border-radius: 100px;
    margin-bottom: 12px;
}

.SC_cardIcon img {
    object-fit: contain;
    height: 90%;
}

.SC_cardHead {
    font-size: 20px;
    font-weight: 700;
    color: var(--secondary);
}

@media only screen and (max-width: 870px) {
    .Solutions {
        padding: 60px 40px 60px;
    }

    .solutionsContainer {
        padding: 20px;
    }

    .SC_Grid {
        display: grid;
        grid-template-columns: 1.1fr 1fr;
        gap: 16px;
    }

    .SC_Cardsflex {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: center;
    }

    .SC_card {
        padding: 32px 20px 32px;
        gap: 10px;
        width: calc(50% - 32px - 10px);
    }

    .SC_card.one {
        width: calc(50% - 32px - 10px);
    }

    .SC_cardIcon {
        height: 120px;
        width: 120px;
    }

    .SC_cardIcon img {
        object-fit: contain;
        height: 90%;
    }

    .SC_cardHead {
        font-size: 20px;
    }

}


@media only screen and (orientation: portrait) {
    .Solutions {
        padding: 60px 20px 60px;
    }

    .solutionsContainer {
        padding: 20px;
    }

    .SC_Grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;
    }

    .SC_Cardsflex {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: center;
    }

    .SC_card {
        /* padding: 16px 10px 24px; */
        gap: 10px;
        width: calc(100% - 32px);
    }

    .SC_card.one {
        width: calc(100% - 32px);
    }

    .SC_cardIcon {
        height: 70px;
        width: 70px;
    }

    .SC_cardIcon img {
        object-fit: contain;
        height: 90%;
    }

    .SC_cardHead {
        font-size: 18px;
    }

}