.Collection {
    display: flex;
    gap: 20px;
    text-align: left;
    padding: 32px 64px;
    max-width: 1100px;
    margin: 0 auto;
}

.collectionSidebar {
    width: 100%;
    max-width: 220px;
}

.C_searchDiv {
    display: flex;
    gap: 16px;
    flex-direction: column;
    background-color: white;
    padding: 24px 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px #0000001a;
    margin-bottom: 20px;
}

.C_ProdFunctions {
    display: flex;
    gap: 16px;
    flex-direction: column;
    background-color: white;
    padding: 24px 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px #0000001a;
}

.C_searchHead {
    font-weight: 700;
    font-size: 15px;
    color: #737373;
}

.C_searchHead.mobile {
    display: flex;
    justify-content: space-between;
}

.C_categoryItem {
    font-size: 12px;
    margin: 9px 0;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    /* text-transform: uppercase; */
    color: #737373;
    padding-left: 8px;
}

.C_filterItem {
    font-size: 12px;
    margin: 9px 0;
    transition: all 100ms ease-in-out;
    /* text-transform: uppercase; */
    color: #000000;
    padding-left: 8px;
}

.C_filterMapGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    margin: 2px 0 10px;
}

.C_filtercheckboxFlex {
    display: flex;
    align-items: center;
    color: #737373;
    font-size: 12px;
}

.C_categoryItem:hover,
.C_categoryItem.bold {
    color: black;
    font-family: 'myfontbold';
}

.collectionMainbar {
    width: 100%;
    background-color: white;
    padding: 24px 40px 64px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px #0000001a;
}


.C_MainHead {
    font-size: 32px;
    color: #737373;
}

.C_productsContainer {
    display: grid;
    grid-template-columns: repeat(3, 32%);
    column-gap: 2%;
    row-gap: 48px;
    margin-top: 32px;
}

.C_productItem {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    cursor: pointer;
    width: 100%;
    max-width: 100%;
}

.swiperBNC {
    width: 100%;
    height: 100%;
}

/* .C_productItem .swiper-slide{
    width: 100%;
    height: 100%;
} */

.swiperSD {
    width: 100% !important;
    height: 100%;
}

.swiperSD .swiper-slide,
.swiperBNC .swiper-slide {
    width: 100% !important;
    height: 100% !important;
}

.C_prodImg {
    width: 100%;
    /* max-width: 100%; */
    /* box-shadow: 0px 5px 10px #0000001a; */
    background-color: var(--grey);
    border-radius: 16px;
    transition: all 100ms ease-in-out;
    border: 0.5px solid transparent;
}

.C_prodImg:hover {
    /* box-shadow: 0px 5px 10px #00000050; */
    /* border: 0.5px solid var(--primary); */
    background-color: #e0e4ee;
}

.C_prodImg>img {
    object-fit: cover;
    border-radius: 16px;
    width: 100%;
    height: 100%;
}

.C_prodText {
    color: #737373;
    font-weight: 700;
    font-size: 15px;
}



.modalContainer {
    position: fixed;
    inset: 0;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.486);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.modal {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    max-width: 800px;
}

.prod_Flex {
    display: flex;
    gap: 12px;
}

.prod_Img {
    width: 100%;
    min-height: 300px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px #0000001a;
    padding: 20px 40px;
    background-color: white;
}

.prod_Img img {
    width: 100%;
    border-radius: 10px;
    height: 100%;
    object-fit: contain;
}

.prod_Content {
    width: 100%;
    min-height: 300px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px #0000001a;
    background-color: white;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    text-align: left;
}

.prod_ContentFLex{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.prod_ContentFlexCol{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
}
.pdfIcon{
    background-color: var(--primary);
    color: white;
    padding: 6px 16px;
    font-size: 14px;
    border-radius: 8px;
    border: none;
    width: fit-content;
    margin-top: 4px;
    margin-left: -0.5px;
}

.prod_modelno {
    font-size: 24px;
    color: var(--secondary);
    font-weight: 500;
}

.prod_category {
    font-size: 15px;
    color: var(--primary);
    /* font-weight: 500; */
}

.prod_features {
    font-size: 12px;
    padding-left: 16px;
    border-top: 1px solid #a6a6a6;
    padding-top: 16px;
}


.prod_btnDiv {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 5px 10px #0000001a;
    padding: 20px 0;
    background-color: white;
    display: flex;
    justify-content: center;
}

.prod_btn {
    background-color: var(--primary);
    color: white;
    padding: 8px 24px;
    font-size: 20px;
    border-radius: 10px;
    border: none;
}

.categoriesSHOWforMobile {
    display: none;
    position: relative;
}

.categoriesdropdown {
    position: absolute;
    top: 20px;
    right: 0px;
    left: 0;
    background-color: var(--grey);
    border: 0.25px solid #00000011;
    box-shadow: 0px 5px 10px #0000001a;
    padding: 16px 20px;
    min-width: 130px;
    border-radius: 5px;
    z-index: 4;
}

.up {
    transform: rotateX(0deg);
    /* 3D rotation along the Y-axis */
    transform-style: preserve-3d;
    /* Ensures children of the element stay in 3D */
    transition: all 400ms ease-in-out;
    perspective: 1000px;
    /* Controls the depth. Higher value = less pronounced 3D effect */
}

.up.down {
    transform: rotateX(180deg);
}

input[type="checkbox"]:checked {
    background-color: var(--primary);
    color: white;
    accent-color: var(--primary);
}


@media only screen and (max-width: 850px) {
    .Collection {
        padding: 32px 40px;
    }

    .collectionSidebar {
        max-width: 180px;
    }

    .C_ProdFunctions {
        padding: 24px 20px;
        gap: 24px;
    }

    .collectionMainbar {
        padding: 24px 20px 64px;
    }

    .C_productsContainer {
        display: grid;
        grid-template-columns: repeat(2, 49%);
        column-gap: 2%;
        row-gap: 48px;
    }

    .prod_Flex {
        display: flex;
    }

    .prod_Img {
        width: 100%;
        padding: 20px 20px;
    }

    .swiperBNC {
        width: 100%;
        height: 100%;
    }

    .swiperSD {
        width: 100% !important;
        /* height: 100%; */
    }

    .swiperSD .swiper-slide,
    .swiperBNC .swiper-slide {
        width: 100% !important;
        /* height: 100% !important; */
    }

    .prod_Content {
        padding: 20px 32px;
    }

    .prod_modelno {
        font-size: 24px;
    }

    .prod_category {
        font-size: 15px;
    }

    .prod_features {
        font-size: 12px;
    }


    .prod_btn {
        font-size: 18px;
    }
}

@media only screen and (orientation: portrait) {
    .Collection {
        padding: 32px 16px;
        /* flex-direction: column; */
        gap: 16px;
    }

    .collectionSidebar {
        /* max-width: unset; */
        max-width: 130px;
    }

    .C_ProdFunctions {
        padding: 24px 16px;
        gap: 24px;
    }

    .collectionMainbar {
        padding: 24px 20px 64px;
        /* width: calc(100% - 40px); */
    }

    .C_productsContainer {
        display: grid;
        grid-template-columns: repeat(1, 100%);
    }

    .C_MainHead {
        font-size: 24px;
    }

    .prod_Flex {
        display: flex;
        flex-direction: column;
    }

    .prod_Img {
        width: calc(100% - 20px);
        min-height: unset;
        height: 150px;
        padding: 4px 10px;
        display: flex;
        justify-content: center;
    }

    .prod_Content {
        width: calc(100% - 40px);
        padding: 20px 20px;
        min-height: unset;
    }

    .prod_modelno {
        font-size: 24px;
    }

    .prod_category {
        font-size: 15px;
    }

    .prod_features {
        font-size: 12px;
    }

    .prod_btnDiv {
        padding: 10px 0;
    }

    .prod_btn {
        font-size: 18px;
        padding: 8px 32px;
    }

    .prod_Img img {
        /* max-width: 60%; */
        border-radius: 10px;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }

    .C_FilterProdDiv {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-top: 8px;
    }

    .categoriesHideforMobile {
        display: none;
    }

    .categoriesSHOWforMobile {
        display: block;
    }

    .C_categoryItem,
    .C_filterItem {
        font-size: 12px;
        margin: 4px 0;
    }

    .C_filterMapGrid {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 8px;
        margin: 2px 0 10px;
    }

    .C_searchHead {
        font-size: 14px;
    }
}