.Product {
    background-color: white;
    box-shadow: 0px 5px 10px #0000001a;
    padding: 16px 16px 32px;
    border-radius: 5px;
    /* max-width: 250px; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin: 28px 0;
    cursor: pointer;
    min-height: 190px;
    transition: all 150ms ease-in-out;
}
.Product:hover{
    box-shadow: 0px 5px 10px #0000004d;
    padding: 26px 16px 38px;
    margin: 20px 0;
}

.ProdImg {
    object-fit: contain;
    width: 80%;
}

.ProdImg img {
    max-width: 100%;
    width: 100%;
}

.prodHead {
    font-size: 18px;
    font-weight: 600;
    color: var(--secondary);
}

.prodText {
    font-size: 13px;
}

@media only screen and (orientation: portrait) {
    .Product {
        min-height: 170px;
        padding: 16px 10px 32px;
    }

    .prodHead {
        font-size: 14px;
    }

    .prodText {
        font-size: 12px;
    }
}