.TrendingProd {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    gap: 32px;
    padding: 64px 0px 80px;
    position: relative;
}

.TP_head {
    font-size: 32px;
    color: var(--secondary);
    text-transform: uppercase;
    letter-spacing: 5px;
}

.TP_cardsFlex {
    display: flex !important;
    width: 100%;
    padding-bottom: 32px;
    /* justify-content: center; */
    /* gap: 20px; */
}

.swiper-button-prev::after, .swiper-button-next::after{
    color: transparent;
    background-color: transparent;
    height: 36px;
    width: 36px;
    border-radius: 0px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 10;
}

.TrendingProd .swiper-button-prev{
    left: 35% !important;
}
.TrendingProd .swiper-button-next{
    right: 35% !important;
}

.swiper-button-prev::after{
    background-image: url(../../../assets/icons/left.png);
    /* transform: translateX(-24px); */
}
.swiper-button-next::after{
    background-image: url(../../../assets/icons/right.png);
}

@media only screen and (orientation: portrait) {

    .TrendingProd .swiper-button-prev::after,
    .TrendingProd .swiper-button-next::after {
        display: none;
    }
}