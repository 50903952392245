.Terms{
    padding: 132px 64px 80px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: justify;
    width: calc(100% - 128px);
    max-width: 800px;
    margin: 0 auto;
    font-size: 14px;
}

.terms_Head{
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    color: var(--secondary);
}

.terms_Title{
    font-size: 16px;
    font-weight: 700;
    color: var(--secondary);
    margin-bottom: 12px;
}

.terms_SubTitle{
    margin-top: 8px;
    font-weight: 700;
}

@media only screen and (orientation: portrait) {
    .Terms{
        padding: 132px 20px 80px;
        width: calc(100% - 40px);
    }
}