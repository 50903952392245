@font-face {
  font-family: 'myfont';
  src: url('./assets/fonts/HelveticaNowDisplay-Regular.woff2') format('woff2');
  /* font-weight: normal; Optional: Specify the weight */
  /* font-style: normal;  Optional: Specify the style */
}

@font-face {
  font-family: 'myfontbold';
  src: url('./assets/fonts/HelveticaNowDisplay-Bold.woff2') format('woff2');
  font-weight: 700; 
  /* font-style: normal;   */
}

@font-face {
  font-family: 'myfontblack';
  src: url('./assets/fonts/HelveticaNowDisplay-ExtraBold.woff2') format('woff2');
  font-weight: 700; 
  /* font-style: normal;   */
}

body{
  background-color: var(--grey);
  font-family: 'myfont';
}

.App {
  text-align: center;
}

a{
  text-decoration: none;
  color: inherit;
}

button{
  cursor: pointer;
  font-family: 'myfont';
}

.bold, strong{
  font-family: 'myfontbold';
  /* font-weight: 800; */
}

.black{
  font-family: 'myfontblack';
}

.whatsappBtnDiv{
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.whatsappBtnDiv>a>img{
  height: 50px;
}


@media only screen and (orientation: portrait) {
  .dummyNav{
    left: 16px;
    right: 16px;
  }
}
